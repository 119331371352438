@import "../../../Global.scss";

.paymentsFormContainer {
  width: 100%;
  height: 85vh;
}

.paymentsFormDiv {
  height: 100%;

  .breadCrumb {
    font-weight: 600;
    font-size: 16px;
    margin-left: 2%;
    padding-top: 10px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1% 1% 1% 1%;

    .headerStart {
      display: flex;
      .backArrowButton {
        margin-right: 10px;
        padding: 0px 10px;
        border-radius: 50px;
        &:hover {
          background-color: rgba(239, 239, 239, 0.674);
        }
      }

      .paymentsFormTitle {
        color: $mainColor;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        margin-bottom: 0%;
      }
    }

    .paymentsFormButton {
      padding: 0px 40px;
      border-radius: 30px;

      &.cancel {
        margin-right: 20px;
      }
    }
  }

  .paymentsFormFields {
    height: 100%;
    scrollbar-width: thin;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 30px;
    overflow: auto;

    .ant-input-outlined[disabled] {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.88) !important;
    }

    .ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.88) !important;
    }

    .ant-input-number-outlined.ant-input-number-disabled input[disabled] {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.88) !important;
    }

    .ant-input-number-input-wrap {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.88) !important;
    }

    .ant-input-number-outlined.ant-input-number-disabled {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.88) !important;
    }

    .paymentsFormNumberFields {
      display: flex;
      justify-content: space-between;
    }

    .paymentsFormFieldsScrollBox {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .paymentsCV {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 30px;
    overflow: auto;
    display: flex;
    height: 1000px;
    display: none;

    &.export {
      display: flex;
    }

    .imagePlaceholder {
      width: 25%;
      height: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      background-color: rgb(118, 85, 138);

      .imageIcon {
        margin: auto;
        margin-top: 20%;
        width: 200px;
        height: 200px;
        object-fit: contain;
      }
    }

    .paymentsDataFieldsContainer {
      margin: auto;
      margin-top: 2%;
      background-image: url("../../../../public/assets/logo4x3Wall.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      .imageLogo {
        width: 300px;
        display: flex;
        margin: auto;
        margin-top: 1%;
      }

      .header {
        display: flex;
        justify-content: center;
        margin: auto;
        text-align: center;
        margin-top: 8%;
        text-decoration: underline;
        font-weight: 600;
      }

      .paymentsDataFields {
        margin-top: 5%;

        span {
          font-weight: 600;
        }

        p {
          font-size: 25px;
          font-weight: 500;
          line-height: 2;
        }
      }
    }
  }

  .paymentsForm {
    width: 98%;
    margin: auto;
    height: fit-content;
  }

  .tableCard {
    margin: auto;
    width: 98%;
  }

  table {
    tr {
      .tableRowLink {
        cursor: pointer;
        color: $mainColor;
        text-decoration: none;
      }

      .tableRowStaffImage {
        display: flex;
        margin: auto;
        width: 50px;
      }
    }
  }
}
