@import "../../../Global.scss";

.employees {
}
.employeeAccountContainer {
  width: 100%;
  height: 100%;

  &.add {
    height: 100vh;
    background-color: rgba(140, 0, 255, 0.031372549);

    .employeeAccountFormButton {
      margin-top: 2%;
    }
  }

  .topBar {
    margin-bottom: 10px;
  }

  .appLogo {
    height: 50px !important;
    cursor: none;
  }

  .userProfile {
    display: none;
  }

  .notificationBell {
    display: none;
  }
}

.employeeAccount {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .headerStart {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;

    .backArrowButton {
      margin-right: 10px;
      padding: 0px 10px;
      border-radius: 50px;
      &:hover {
        background-color: rgba(239, 239, 239, 0.674);
      }
    }

    .employeeAccountTitle {
      color: $mainColor;
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      margin-top: 2%;
      margin-bottom: 2%;

      &.add {
        margin-left: 37%;
        margin-top: 1%;
        margin-bottom: 10px;
      }
    }
  }

  .employeeAccountFormFields {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 30px;
  }

  .employeeAccountForm {
    width: 80%;
  }

  .employeeAccountFormButton {
    margin-top: 2%;
    margin-left: 45%;
    padding: 0px 40px;
    border-radius: 30px;
  }
}
