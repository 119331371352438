@import "../../Global.scss";

.sideBar {
  position: relative;
  height: 100%;
  // width: 12%;
  width: 180px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: width 0.05s ease-in-out;

  &.collapseSideBar {
    // width: 5%;
    width: 70px;
  }

  // &:hover {
  //   width: 5%;
  // }
  .sideBarItems {
    height: 80%;
    margin-bottom: 30px;

    .sideBarItemLink {
      color: black;
      text-decoration: none;

      .sideBarItemContainer {
        height: 13%;
        // background-color: red;

        .sideBarItem {
          display: flex;
          height: 100%;
          width: 180px;
          align-items: center;
          font-weight: 500;
          border: 1px solid rgb(223, 223, 223);

          &.collapseSideBar {
            // width: 5%;
            width: 70px;
          }

          .sideBarItemBanner {
            // width: 2%;
            width: 3px;
            height: 100%;
            // margin-right: 10%;
            margin-right: 20px;
          }

          .sideBarItemDetails {
            width: 180px;
            display: flex;
            // width: 88%;
            font-weight: 600;
            color: #444242;

            .sideBarItemTitle {
              display: block;
              width: 100%;
              opacity: 1;
              transition: opacity 0.05s ease-in-out;

              &.collapseSideBar {
                display: none;
                width: 0%;
                opacity: 0;
                transition: opacity 0.05s ease-in-out;
              }
            }

            &.collapseSideBar {
              width: 0px;
            }
          }

          .sideBarItemIcon {
            color: $mainColor;
            font-size: 25px;
            margin-right: 10px;
          }

          &.activeItem {
            font-weight: 600;
            .sideBarItemBanner {
              background-color: $mainColor;
            }

            &.collapseSideBar {
              .sideBarItemBanner {
                // height: 3px;
              }
            }
          }

          &:hover {
            font-weight: 600;
            background-color: #f1f7fd;
          }
        }
      }
    }
  }

  .collapseSideBarDiv {
    display: flex;
    justify-content: center;
    // height: 10%;
    // width: 100%;
    // background-color: red;
    margin: auto;
    .collapseSideBarButton {
      margin-top: 10px;
      color: $mainColor;
      font-size: 30px;
    }
  }
}
