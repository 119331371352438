@import "../../Global.scss";

.resetPasswordContainer {
  width: 100%;
  height: 100vh;
  background-color: rgba(140, 0, 255, 0.031372549);

  &.edit {
    height: 100%;
  }

  .topBar {
    margin-bottom: 10px;
  }

  .appLogo {
    height: 50px !important;
    cursor: none;
  }

  .userProfile {
    display: none;
  }

  .notificationBell {
    display: none;
  }
}

.resetPassword {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .headerStart {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    height: 20%;

    .backArrowButton {
      padding: 0px 10px;
      border-radius: 50px;
      &:hover {
        background-color: rgba(239, 239, 239, 0.674);
      }
    }

    .resetPasswordTitle {
      color: $mainColor;
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      margin-top: 6%;
      margin-bottom: 5%;
      margin-right: 25%;
      margin: auto;
    }
  }

  .resetPasswordFormFields {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 20px;
  }

  .resetPasswordForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    margin-top: 1%;
  }

  .resetPasswordFormButton {
    width: 60%;
    margin-top: 10%;
    padding: 0px 10px;
    border-radius: 30px;
  }
}
