@import "../../../Global.scss";
.tablePage {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1%;
    margin-bottom: 1%;

    .headerText {
      color: $mainColor;
      margin-left: 20px;
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    }

    .headerLink {
      text-decoration: none;
      color: white;
      background-color: $mainColor;
      padding: 10px 20px;
      border-radius: 40px;
      font-size: 13px;
      font-weight: 500;
      margin-right: 20px;
      &:hover {
        background-color: $mainColorHover;
        font-weight: 700;
      }
    }
  }

  .tableCard {
    margin: auto;
    width: 98%;
  }

  .searchBar {
    display: flex;
  }

  .clearFiltersButton {
    margin-left: 20px;
  }

  .tableMenuDiv {
    height: 100%;
    width: 100%;
    text-align: center;
    &:hover {
      background-color: $secondaryColorBackground;
    }
    .tableMenuIcon {
      font-size: 30px;
    }
  }

  table {
    tr {
      .tableRowLink {
        cursor: pointer;
        color: $mainColor;
        text-decoration: none;
      }
    }
  }
}
