@import "../../../Global.scss";

.employeeFormContainer {
  width: 100%;
  height: 85vh;
}

.employeeFormDiv {
  height: 100%;

  .breadCrumb {
    font-weight: 600;
    font-size: 16px;
    margin-left: 2%;
    padding-top: 10px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1% 1% 1% 1%;

    .headerStart {
      display: flex;
      .backArrowButton {
        margin-right: 10px;
        padding: 0px 10px;
        border-radius: 50px;
        &:hover {
          background-color: rgba(239, 239, 239, 0.674);
        }
      }

      .employeeFormTitle {
        color: $mainColor;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        margin-bottom: 0%;
      }
    }

    .employeeFormButton {
      padding: 0px 40px;
      border-radius: 30px;

      &.cancel {
        margin-right: 20px;
      }
    }
  }

  .employeeFormFields {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 30px;

    .ant-input-outlined[disabled] {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.88) !important;
    }

    .ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.88) !important;
    }
  }

  .employeeForm {
    width: 98%;
    margin: auto;
  }

  //   .employeeFormButton {
  //     margin-top: 30px;
  //     margin-bottom: 10px;
  //     margin-left: 45%;
  //   }
}
