@import "../../Global.scss";

.login {
  display: flex;
  width: 100vw;
  height: 100vh;
  z-index: 2;

  .loginBackground {
    flex: 0.5;
    background-image: url("../../../public/assets/login.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    @include mobile {
      flex: 0;
    }
    @media screen and (min-width: $mobileWidth) {
      background-size: cover;
    }
  }

  .loginFormContainer {
    flex: 0.5;
    justify-content: center;
    margin: 10px;
    padding: 10px;
    background-image: url("../../../public/assets/logo4x3Wall.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @include mobile {
      flex: 1;
    }

    .loginForm {
      margin: auto;
      margin-top: 1%;
      width: 60%;
      text-align: center;
      @media screen and (max-width: $noteBookWidth) {
        margin-left: 0;
        width: 100%;
      }

      .logoContainer {
        background-image: url("../../../public/assets/logo-banner.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 80px;
        width: 60%;
        margin: auto;
        @include mobile {
          width: 100%;
        }
        @media screen and (min-width: $noteBookWidth) {
          background-size: 100% 100%;
          width: 60%;
          margin-top: 0px;
          margin-bottom: 1%;
        }
      }

      .loginFormTitle {
        color: $mainColor;
        margin-top: 1%;
        font-weight: 500;
        @media screen and (min-width: $noteBookWidth) {
          margin-bottom: 1%;
        }
        @media screen and (min-width: $mobileWidth) {
          // margin-top: 0px;
        }
      }

      .loginFormFieldsContainer {
        margin: auto;
        width: 90%;
        font-weight: 600;
        font-size: 26px !important;
        padding: 20px;
        background-color: #ffffffa6 !important;
        border: 1px solid rgb(206, 206, 206);
        border-radius: 25px;
        margin-top: 10px;
        @include mobile {
          width: 100%;
          .input-otp__field {
            font-size: 1em;
            height: 50px;
            margin-bottom: 10px;
          }
        }

        &:hover {
          background-color: rgba(248, 248, 255, 0.744) !important;
        }

        .OTPComponent {
          margin-bottom: 40px;

          .input-otp__field {
            font-size: 1.5em;
            height: 50px;
            margin-bottom: 10px;
          }

          .ant-form-item {
            margin-bottom: 40px;
          }

          .OTPTextMessage {
            text-align: center;
            font-size: larger;
            .OTPTextRecepientEmail {
              color: $mainColor;
            }
          }
        }

        .formButtons {
          margin-top: 20px;
          display: flex;
          justify-content: space-evenly;
        }
      }
    }

    .forgotPasswordLink {
      margin-top: 5%;
      margin-bottom: 0%;
      // font-weight: 600;
      color: $mainColor;
      font-size: 12px;
    }
  }
}

/* Backgrond Animation*/
@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  margin: auto;

  li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgb(0 143 225 / 40%);
    animation: animate 25s linear infinite;
    bottom: -150px;
  }

  li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }

  li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }

  li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }

  li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }

  li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }

  li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }

  li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }

  li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }

  li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }

  li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
  }
}
