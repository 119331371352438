@import "../../Global.scss";

.ant-modal-title {
  color: $mainColor !important;
  font-size: 20px !important;
}

.modalMessage {
  font-weight: 500;
  font-size: 16px;
}

.ant-btn-primary {
  background-color: $mainColor !important;
  font-weight: 600;

  &:hover {
    background-color: $mainColorHover !important;
  }
}
