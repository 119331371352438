@import "../../../Global.scss";

.staffFormContainer {
  width: 100%;
  height: 85vh;
}

.staffFormDiv {
  height: 100%;

  .breadCrumb {
    font-weight: 600;
    font-size: 16px;
    margin-left: 2%;
    padding-top: 10px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1% 1% 0.5% 1%;

    &.edit {
      margin-top: 0px;
    }

    .headerStart {
      display: flex;
      .backArrowButton {
        margin-right: 10px;
        padding: 0px 10px;
        border-radius: 50px;
        &:hover {
          background-color: rgba(239, 239, 239, 0.674);
        }
      }

      .staffFormTitle {
        color: $mainColor;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        margin-bottom: 0%;
        margin-right: 250px;
      }

      .staffStatusTag {
        margin: auto;
        padding: 5px 10px;
        border-radius: 0.5rem;
        font-size: 12px;
        font-weight: 500;

        &.unassigned {
          color: #d48806;
          background: #fffbe6;
          border: 1px solid #ffe58f;
          border-color: #ffe58f;
        }

        &.assigned {
          color: #389e0d;
          background: #f6ffed;
          border: 1px solid #f6ffed;
          border-color: #b7eb8f;
        }

        &.inactive {
          color: #cf1322;
          background: #fff1f0;
          border: 1px solid #ffa39e;
          border-color: #ffa39e;
        }
      }
    }

    .staffFormButton {
      padding: 0px 40px;
      border-radius: 30px;

      &.cancel {
        margin-right: 20px;
      }

      &.cv {
        margin-right: 20px;
      }
    }

    .headerLink {
      text-decoration: none;
      color: white;
      background-color: $mainColor;
      padding: 8px 20px;
      border-radius: 40px;
      font-size: 13px;
      font-weight: 500;
      margin-right: 20px;
      &:hover {
        background-color: $mainColorHover;
      }
    }
  }

  .staffFormFields {
    height: 100%;
    scrollbar-width: thin;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 30px;
    overflow: auto;

    .uploadImagePlaceholder {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .uploadImageIcon {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 50%;
      }

      .pdfIcon {
        font-size: 80px;
        color: $mainColor;
        margin-left: 3%;
      }

      .uploadImageButton {
        margin-left: 30px;
      }
    }

    .ant-input-outlined[disabled] {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.88) !important;
    }

    .ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.88) !important;
    }

    .ant-input-number-outlined.ant-input-number-disabled input[disabled] {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.88) !important;
    }

    .ant-input-number-input-wrap {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.88) !important;
    }

    .ant-input-number-outlined.ant-input-number-disabled {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.88) !important;
    }

    .staffFormNumberFields {
      display: flex;
      justify-content: space-between;
    }

    .staffFormFieldsScrollBox {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .staffCVDownloadButton {
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    justify-content: center;
  }

  .staffCV {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 30px;
    overflow: auto;
    display: flex;
    height: 1000px;
    display: none;

    &.export {
      display: flex;
    }

    .imagePlaceholder {
      width: 25%;
      height: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      background-color: rgb(118, 85, 138);

      .imageIcon {
        margin: auto;
        margin-top: 20%;
        width: 200px;
        height: 125px;
        object-fit: contain;
        border-radius: 50%;
      }
    }

    .staffDataFieldsContainer {
      margin: auto;
      margin-top: 2%;
      background-image: url("../../../../public/assets/logo4x3Wall.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      .imageLogo {
        height: 100px;
        width: 400px;
        display: flex;
        margin: auto;
        margin-top: 1%;
      }

      .header {
        display: flex;
        justify-content: center;
        margin: auto;
        text-align: center;
        margin-top: 8%;
        text-decoration: underline;
        font-weight: 600;
      }

      .staffDataFields {
        margin-top: 5%;
        width: 100%;

        span {
          font-weight: 600;
        }

        p {
          font-size: 25px;
          font-weight: 500;
          line-height: 2;
        }
      }
    }
  }

  .staffForm {
    width: 98%;
    margin: auto;
    height: 75%;

    &.edit {
      height: 70%;
    }
  }

  .tableCard {
    margin: auto;
    width: 98%;
  }

  table {
    tr {
      .tableRowLink {
        cursor: pointer;
        color: $mainColor;
        text-decoration: none;
      }

      .tableRowStaffImage {
        display: flex;
        margin: auto;
        width: 50px;
      }
    }
  }
}
