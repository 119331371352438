@import "../../Global.scss";

.dashboardItemCard {
  width: 95%;
  height: 90%;
  border-radius: 30px;
  background-color: #ffffff9e;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

  .dashboardItemCardIcon {
    font-size: 40px;

    svg {
      color: white;
      background-color: #ae61b4;
      height: 80%;
      padding: 20%;
      border-radius: 50%;
    }
  }

  .dashboardItemCardTitle {
    margin: 2px;
    margin-top: 10px;
    color: #000000b0;
    font-size: 20px;
    font-weight: 600;
  }

  .dashboardItemCardDescription {
    color: rgb(1 1 1 / 50%);
    margin-top: 5px;
    font-size: 16px;
    padding: 0px 40px 0px 40px;
    font-weight: 500;
    text-align: center;
  }

  .dashboardItemLink {
    font-size: 16px;
    text-decoration: none;
    font-weight: 500;
    border: 1px solid $mainColor;
    border-radius: 50px;
    padding: 5px 15px;
    display: inline-block;
    color: $mainColor;
    cursor: pointer;

    &:hover {
      font-weight: 600;
      background-color: #a671aa;
      color: white;
      border: 1px solid white;
    }
  }
}
