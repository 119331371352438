@import "../../Global.scss";

.notificationBox {
  position: fixed;
  right: 0;
  margin-top: 5.5%;
  margin-right: 1.5%;
  width: max-content;
  height: max-content;
  background-color: #fff;
  border: 1px solid rgb(198, 198, 198);
  border-top: none;
  z-index: 2;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-right: 0.5%;

  .notificationItemLink {
    text-decoration: none;

    .notificationItem {
      height: 50px;
      // border: 1px solid rgb(227, 227, 227);
      padding: 10px 5px;
      font-weight: 500;
      cursor: pointer;

      &.alertNotification {
        color: rgb(188 132 0);
      }

      &.errorNotification {
        color: rgb(255, 68, 0);
      }

      &.profileDetails {
        color: $mainColor;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        margin-top: 2%;
      }

      &.logoutProfileSetting {
        color: rgb(255, 68, 0);
      }

      .notificationItemIcon {
        margin-left: 5px;
        margin-right: 10px;
      }

      &:hover {
        font-weight: 500;
        background-color: $secondaryColorHover;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }
}
