@import "../../Global.scss";

.spinner-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.867);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.spinner-border {
  width: 5rem;
  height: 5rem;
  color: $mainColor !important;
}
