@import "../../../Global.scss";

.tableRecordDeleteIcon {
  color: red;
  font-size: 20px;
  cursor: pointer;

  &:hover {
    background-color: $secondaryColorBackground;
  }
}

.tableHeader {
  display: flex;
  justify-content: space-between;
}
