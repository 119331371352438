@import "../../Global.scss";

.tableActionComponent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
  background-color: ghostwhite;
  padding: 8px;
  border-radius: 10px;
  position: fixed;
  z-index: 9;
  border-bottom: 1px solid rgb(211, 211, 211);

  .tableActionItems {
    cursor: pointer;
    .tableActionItem {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: auto;
      padding: 4px 1px;
      &:hover {
        background-color: rgb(238, 238, 238);
      }

      .tableActionTitle {
        margin-left: 10px;
        font-weight: 600;
      }
    }
  }

  .tableActionIcon {
    font-size: 20px;

    &.view {
      color: green;
    }

    &.edit {
      color: purple;
    }

    &.inactive,
    &.delete {
      color: red;
    }

    &.active,
    &.convert,
    &.paid {
      color: green;
    }

    &.download {
      color: blue;
    }
  }
}
