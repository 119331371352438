@import "../../../Global.scss";
.salariesTablePage {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1%;
    margin-bottom: 1%;

    .headerText {
      color: $mainColor;
      margin-left: 20px;
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    }

    .headerButton {
      text-decoration: none;
      color: white;
      background-color: $mainColor;
      padding: 0px 30px;
      border-radius: 40px;
      font-size: 13px;
      font-weight: 500;
      margin-right: 20px;
      &:hover {
        color: white;
        background-color: $mainColorHover;
        font-weight: 700;
      }
    }
  }

  .tableCard {
    margin: auto;
    width: 98%;
  }

  .searchBar {
    display: flex;

    .salaryMonthPicker {
      width: 15%;
      margin-left: 2%;
    }
  }

  .clearFiltersButton {
    margin-left: 20px;
  }

  .tableMenuDiv {
    height: 100%;
    width: 100%;
    text-align: center;
    &:hover {
      background-color: $secondaryColorBackground;
    }
    .tableMenuIcon {
      font-size: 30px;
    }
  }

  table {
    tr {
      .tableRowLink {
        cursor: pointer;
        color: $mainColor;
        text-decoration: none;
      }
    }
  }

  .salarySlip {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 30px;
    overflow: auto;
    display: flex;
    height: 1000px;
    display: none;

    &.export {
      display: flex;
    }

    .staffDataFieldsContainer {
      margin: auto;
      margin-top: 2%;
      background-image: url("../../../../public/assets/logo4x3Wall.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      .imageLogo {
        width: 300px;
        display: flex;
        margin: auto;
        margin-top: 1%;
      }

      .header {
        display: flex;
        justify-content: center;
        margin: auto;
        text-align: center;
        margin-top: 8%;
        text-decoration: underline;
        font-weight: 600;
      }

      .staffDataFields {
        margin-top: 5%;

        span {
          font-weight: 600;
        }

        p {
          font-size: 25px;
          font-weight: 500;
          line-height: 2;
        }
      }
    }
  }

  .recordsCount {
    // margin-left: 48% !important;
    justify-content: right !important;
    width: 150px !important;
  }
}
