@import "../../../Global.scss";

.patientFormContainer {
  width: 100%;
  height: 85vh;
}

.patientFormDiv {
  height: 100%;

  .breadCrumb {
    font-weight: 600;
    font-size: 16px;
    margin-left: 2%;
    padding-top: 10px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1% 1% 1% 1%;

    &.edit {
      margin-top: 0px;
    }

    .headerStart {
      display: flex;
      .backArrowButton {
        margin-right: 10px;
        padding: 0px 10px;
        border-radius: 50px;
        &:hover {
          background-color: rgba(239, 239, 239, 0.674);
        }
      }

      .patientFormTitle {
        color: $mainColor;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        margin-bottom: 0%;
        margin-right: 250px;
      }

      .patientStatusTag {
        margin: auto;
        padding: 5px 10px;
        border-radius: 0.5rem;
        font-size: 12px;
        font-weight: 500;

        &.unassigned {
          color: #d48806;
          background: #fffbe6;
          border: 1px solid #ffe58f;
          border-color: #ffe58f;
        }

        &.assigned {
          color: #389e0d;
          background: #f6ffed;
          border: 1px solid #f6ffed;
          border-color: #b7eb8f;
        }

        &.inactive {
          color: #cf1322;
          background: #fff1f0;
          border: 1px solid #ffa39e;
          border-color: #ffa39e;
        }
      }

      .patientFormTitle {
        color: $mainColor;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        margin-bottom: 0%;
      }
    }

    .patientFormButton {
      padding: 0px 40px;
      border-radius: 30px;

      &.cancel {
        margin-right: 20px;
      }

      &.cv {
        margin-right: 20px;
      }
    }
  }

  .patientFormFields {
    height: 100%;
    scrollbar-width: thin;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 30px;
    overflow: auto;

    .stepDescriptionText {
      width: 100%;
      color: #000000ad;
      margin-bottom: 1%;
      display: flex;
      align-items: center;
    }

    .ant-input-outlined[disabled] {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.88) !important;
    }

    .ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.88) !important;
    }

    .ant-input-number-outlined.ant-input-number-disabled input[disabled] {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.88) !important;
    }

    .ant-input-number-input-wrap {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.88) !important;
    }

    .ant-input-number-outlined.ant-input-number-disabled {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.88) !important;
    }

    .patientFormNumberFields {
      display: flex;
      justify-content: space-between;
    }

    .patientFormFieldsScrollBox {
      overflow-y: auto;
      overflow-x: hidden;
    }

    .billingCycleDays {
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: $mainColor;
      margin-top: 1%;
      margin-bottom: 3%;
    }
  }

  .patientForm {
    width: 98%;
    height: 75%;
    margin: auto;

    &.edit {
      height: 68%;
    }
  }

  .searchBar {
    display: flex;
    .ant-input-outlined {
      width: 300px;
    }

    .paymentMonthPicker {
      width: 15%;
      margin-left: 2%;
    }
  }

  .clearFiltersButton {
    margin-left: 20px;
  }

  .tableCard {
    margin: auto;
    width: 98%;
  }

  table {
    tr {
      .tableRowLink {
        cursor: pointer;
        color: $mainColor;
        text-decoration: none;
      }

      .tableRowpatientImage {
        display: flex;
        margin: auto;
        width: 50px;
      }
    }
  }
}

.ant-picker-footer {
  display: none;
}
