@import "../../Global.scss";

.invalidPage {
  width: 100%;
  height: 100%;
  position: absolute;
  // margin: auto;
  display: flex;

  .invalidBackgroundImg {
    flex: 0.5 1;
    // width: 100%;
    // height: 75%;
    background-image: url("../../../public/assets/invalidPage.gif");
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // margin: auto;
  }

  .invalidMessage {
    flex: 0.5 1;
    text-align: center;

    .invalidTitle {
      text-align: justify;
      margin: 3%;
      margin-bottom: 30px;

      color: #b55757;
      h1 {
        line-height: 3.5rem;
      }
    }

    .homeButton {
      font-size: 30px;
      font-weight: 500;
      background-color: $mainColor;
      border-radius: 30px;
      text-decoration: none;
      color: white;
      padding: 10px 20px;
      &:hover {
        background-color: $mainColorHover;
      }
    }

    .invalidBackgroundImg2 {
      margin: auto;
      margin-top: 20px;
      height: 200px;
      width: 180px;
      background-image: url("../../../public/assets/dog.gif");
      object-fit: cover;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
}
