@import "../../Global.scss";
* {
  scrollbar-width: thin;
}

.dashboard {
  background-color: #f8fdfb;
  height: 100vh;
  width: 100vw;

  .appBody {
    display: flex;
    height: 88%;
    background-color: #f7fbfd;
    padding-top: 0.5%;

    .contentBody {
      height: 100%;
      width: 88%;
      background-image: url("../../../public/assets/logo4x3Wall.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      background-color: #8c00ff08;
      overflow: auto;

      &.expandContentBody {
        width: 95%;
      }

      .outletBorder {
        margin: 0% 0.5%;
        height: 97%;
      }

      .ant-table tr:nth-child(even) td {
        background-color: #faf5fc;
      }

      .ant-table-tbody {
        text-align: center;
      }

      .ant-table thead {
        background-color: #f1f1f1;
      }

      .ant-table-thead {
        .ant-table-cell {
          text-align: center;
        }
      }

      .ant-table-wrapper .ant-table-column-title {
        text-align: center;
      }

      .ant-table-cell {
        color: #000000ad;
        padding: 10px 5px !important;
        font-weight: 500;
        border-bottom: 1px solid #eaeaea;
        border-right: 1px solid #eaeaea;
      }

      .ant-table-wrapper .ant-table-pagination.ant-pagination {
        margin: 0.5%;
      }

      .ant-btn-primary {
        background-color: $mainColor !important;
        font-weight: 600;

        &:hover {
          background-color: $mainColorHover !important;
        }
      }

      .ant-dropdown .ant-table-filter-dropdown .ant-dropdown-menu {
        max-height: 100px !important;
      }

      .ant-dropdown-menu-submenu .ant-dropdown-menu {
        max-height: 200px;
      }

      .ant-card .ant-card-body {
        padding: 20px 20px 20px 20px;
      }

      .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $mainColor;
      }

      .ant-tabs .ant-tabs-ink-bar {
        background: $mainColor;
      }

      .ant-tabs .ant-tabs-tab-btn {
        font-size: 14px;
        font-weight: 600;
        &:hover {
          color: $mainColor;
        }
      }

      .ant-tabs .ant-tabs-tab {
        padding: 8px 0;
      }

      .ant-tabs .ant-tabs-tab:hover {
        color: $mainColor;
      }

      .tabs {
        margin-left: 20px;
      }

      .ant-picker.ant-picker-outlined {
        width: 100% !important;
      }

      .ant-picker.ant-picker-disabled {
        background-color: transparent;
      }

      .ant-picker .ant-picker-input > input[disabled] {
        color: rgba(0, 0, 0, 0.88) !important;
      }

      .ant-table-body {
        overflow-y: auto !important;
      }

      .ant-tabs > .ant-tabs-nav {
        margin: 0 0 0.5% 0;
      }

      .ant-table-wrapper.table {
        margin-bottom: 0px;
      }

      .ant-pagination-options {
        display: none;
      }

      .recordsCount {
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $mainColor;
        font-weight: 500;

        span {
          margin-right: 5%;
        }

        p {
          width: fit-content;
          margin-bottom: 0%;
          margin-top: 0%;
        }
      }

      hr {
        margin-bottom: 0;
      }
    }
  }
}
