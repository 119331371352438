@import "../../Global.scss";

.modalForm {
  text-align: center;
  .titleText {
    color: $mainColor;
    // margin-left: 20px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 700;
  }

  .employeeAccountFormFields {
    margin-top: 1%;
    margin-bottom: 1%;
    font-weight: 600;

    .ant-picker-outlined {
      width: 100%;
    }
  }

  .addEmployeeFormButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .addEmployeeFormButton,
    .cancelEmployeeFormButton {
      width: 100%;
      border-radius: 30px;
      text-align: center;
    }
  }

  .uploadImagePlaceholder {
    .iconsBar {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 7%;
      margin-bottom: 7%;
      .uploadImageIcon {
        width: 25%;
        height: 25%;
      }
    }

    .progressBar {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 5%;
    }

    .pdfIcon {
      font-size: 70px;
      color: $mainColor;
    }

    .uploadBarDiv {
      display: flex;
      margin-bottom: 5%;
    }

    .uploadImageButton {
      // width: 150px;
    }
  }
}
