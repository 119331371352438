// $mainColor: #1977cc;

$mainColor: #964d9b;
$mainColorHover: #af57a8;
$secondaryColorHover: #f7fdfe;
$secondaryColorBackground: #f1f7fd;

$mobileWidth: 600px;
$noteBookWidth: 1000px;
$filterMenuHeight: 200px;

@mixin mobile {
  @media (max-width: #{$mobileWidth}) {
    @content;
  }
}
