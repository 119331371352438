@import "../../Global.scss";

.topBar {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 12%;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 3;
  // cursor: none !important;
  .logoContainer {
    margin-left: 1%;
    height: 100%;
    width: 12%;
    .appLogo {
      background-image: url("../../../public/assets/logo-banner.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 80%;
      margin-left: 1%;
      margin-top: 4%;
      cursor: pointer;
    }
  }

  .topBarItems {
    display: flex;
    flex-direction: row;
    margin-right: 5%;

    .userProfile {
      margin: auto;
      color: $mainColor;
      font-size: 25px;
      padding: 5px 10px;
      border: 3px solid rgb(204, 204, 204);
      border-radius: 50%;

      &:hover {
        border: 3px solid $mainColor;
      }

      &.active {
        border: 3px solid $mainColor;
      }
    }

    .notificationBell {
      margin: auto;
      font-size: 25px;
      padding: 5px 10px;
      margin-right: 50%;

      &.active {
        color: $mainColor;
      }
    }
  }
}
